
import React from "react"
import PropTypes from "prop-types" 
import { Link } from "gatsby"

const Location = ({ imgSrc, altTag, location, address, cityState,  hours, weekends, pageURL, reverse }) => (
        <div className="container is-white pt-4">
            <div className={`row ${reverse && `flex-row-reverse`}`}>
                <div className="col-sm p-4" style={{ borderRadius: '6px' }}>
                    <img style={{ width: '100%', borderRadius: '6px' }} src={imgSrc} alt={altTag} loading="lazy"/>  
                </div>
                <div className="col-sm d-flex justify-content-center flex-column pl-4">
                    <h2 className="is-purple pt-4">{location}</h2>
                    <p className="is-purple pt-4 font-weight-bold">{address}
                    <span> <br/>{cityState}</span></p>
                    <p className="is-purple">{hours}</p>
                    <p className="is-purple">{weekends}</p>
                    <div className="mt-3 team-meet">
                        <Link className="btn btn-success my-2 my-sm-0 pl-4 pr-4 pt-3 pb-3" to={pageURL}>Meet the team</Link>
                    </div>   
                </div>
            </div>
        </div>
)

Location.propTypes = {
    imgSrc: PropTypes.string,
    altTag: PropTypes.string,
    location: PropTypes.string,
    address: PropTypes.string,
    cityState: PropTypes.string,
    hours: PropTypes.string,
    weekends: PropTypes.string,
    pageURL: PropTypes.string,
    reverse: PropTypes.bool,
}

Location.defaultProps = {
    imgSrc: '',
    altTag: '',
    location: '',
    address: '',
    cityState: '',
    hours: '',
    weekends: '',
    pageURL: '',
    reverse: false,
}

export default Location
